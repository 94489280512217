import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false
        },
        title: {
            display: false,
            text: 'Chart.js Line Chart',
        },
    },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
    labels,
    datasets: [
        {
            label: 'Foydalanuvchilar',
            data: [-200, -120, 0, 100, 240, 800],
            borderColor: '#5243AA',
            backgroundColor: '#5243AA',
            hoverBackgroundColor: "blue",
            hoverBorderColor: "blue",
            borderWidth: 5,
            hoverBorderWidth: 10,
            pointBorderWidth: 8,
            lineTension: 0.5
        },
        {
            label: 'E`lonlar',
            data: [100, -20, 0, 10, 160, 500, 600],
            borderColor: '#00875A',
            backgroundColor: '#00875A',
            hoverBorderColor: "blue",
            borderWidth: 5,
            hoverBorderWidth: 10,
            pointBorderWidth: 8,
            lineTension: 0.5
        },
    ],
};

const Chart = () => {
    return (
        <div className='chart'>
            <div className="chart-header">
                <h3 className="chart-header-title">
                    Grafik tahlil
                </h3>
                <div className="lines-meaning">
                    <div className="line-meaning-item">
                        <div className="line-color" id='color-1'></div>
                        <span className="line-meaning">Foydalanuvchilar soni</span>
                    </div>
                    <div className="line-meaning-item">
                        <div className="line-color" id='color-2'></div>
                        <span className="line-meaning">E`lonlar soni</span>
                    </div>
                </div>
            </div>
            <body className="chart-body">
                <Line options={options} data={data} />
            </body>
        </div>
    )
}

export default Chart