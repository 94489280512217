import { SetStateAction, Dispatch } from 'react';
import { useAppSelector } from '../../../hook/useTypedSelector';


const NotificationsTable = ({ selectedData, setSelectedData }: { selectedData: number[], setSelectedData: Dispatch<SetStateAction<number[]>> }) => {
  const { data } = useAppSelector(state => state);

  const handleSelectedData = ({ e, pk, all }: { e?: any, pk?: number, all?: boolean }) => {
    if (all) {
      if (selectedData.length === data.length) {
        setSelectedData([])
      } else {
        setSelectedData(data.map(({ pk }) => pk))
      }
    } else if (!all && pk && e) {
      if (e.target.checked) {
        setSelectedData(init => [...init, pk])
      } else {
        setSelectedData((init) => init.filter(id => id !== pk))
      }
    }
  };


  return (
    <>
      <tr>
        <th>
          <input
            checked={selectedData.length === data.length}
            onChange={() => handleSelectedData({ all: true })}
            type="checkbox" 
            name="notifications" id="" 
            style={{ transform: 'scale(1.5)', cursor: 'pointer' }} 
          />
        </th>
        <th className="row-header">Bildirish noma sarlavhasi</th>
        <th className="row-header">Sana / Vaqt</th>
      </tr>
      {
        data.map(({ date, theme, time, pk }) => (
          <tr key={pk}>
            <td className="row-item item-id">
              <input
                type="checkbox"
                name="notifications"
                id=""
                checked={selectedData.includes(pk)}
                style={{ transform: 'scale(1.5)', cursor: 'pointer' }}
                onChange={(e) => handleSelectedData({ e, pk })}
              />
            </td>
            <td className="row-item notification-limit-length">{theme}</td>
            <td className="row-item item-btn">{date} / {time}</td>
          </tr>
        ))
      }
    </>
  )
}

export default NotificationsTable;