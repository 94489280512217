import { useState } from 'react';
import { adExactDataType } from '../../../data/types';
import { useAppSelector } from '../../../hook/useTypedSelector';
import useFetch from "../../../hook/useFetch";
import Modal from '../../Modal/Modal'


const AdTable = () => {
    const { data } = useAppSelector(state => state);
    const [adExactData, setAdExactData] = useState<adExactDataType | null>(null);
    const [open, setOpen] = useState(false);
    const { request } = useFetch();

    const status = [
        {
            color: '#1BD530',
            text: 'Qabul qilingan',
            type: 'published'
        },
        {
            color: 'red',
            text: 'Bekor qilingan',
            type: 'rejected'
        },
        {
            color: '#12ABFF',
            text: 'Ko`rilmagan',
            type: 'inactive'
        }
    ];

    const handleExactData = (pk: number) => {
        setOpen(true);
        request(`/product/admin/get/announce/byid/${pk}`, "GET", null, {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        })
            .then(result => {
                if (result.success) {
                    setAdExactData(result)
                }
            })
            .catch(err => console.log(err))
    };


    return (
        <>
            <tr className="row-headers-wrapper">
                <th className="row-header">Nomeri</th>
                <th className="row-header">E`lon</th>
                <th className="row-header">Foydalanuvchi</th>
                <th className="row-header">Kategoriya</th>
                <th className="row-header">Status</th>
            </tr>
            {
                data?.map(item =>{ 
                    const index = status.findIndex(element => element.type === item.status)
                    return (
                    <tr onClick={() => handleExactData(item.pk)}>
                        <td className="row-item item-id">{item.pk}</td>
                        <td className="row-item ad-limit-length">{item.title}</td>
                        <td className="row-item">{item.user}</td>
                        <td className="row-item">{item.category}/{item.subcategory}</td>
                        <td className="row-item item-btn">
                            <button style={{background: status[index]?.color}} className="row-item-btn">{status[index]?.text}</button>
                        </td>
                    </tr>
                )})
            }
            <Modal data={adExactData} open={open} setOpen={setOpen} />
        </>
    )
}

export default AdTable