import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import DeleteIcon from '../../../assets/svg/delete_icon_red.svg';
import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { menuItems } from '../../../data/menuItems';
import { menuItemStyle, selectStyle } from '../../../data/styles'
import { useAppSelector } from '../../../hook/useTypedSelector';
import { changeRandom, getCategories, handleCategoryFilter, handleCurrentPage, handleSearchValue } from '../../../redux/actions';
import useFetch from '../../../hook/useFetch';

interface HeaderComponentInterface { id: number, handleFilterItem: (e: any) => void, selectedData: number[], setSelectedData: Dispatch<SetStateAction<number[]>> }

const HeaderComponent = ({ id, handleFilterItem, selectedData, setSelectedData }: HeaderComponentInterface) => {
    const { categories, typeOfData, filterItem, categoryFilterItem } = useAppSelector(state => state);
    const dispatch = useDispatch();
    const { request } = useFetch();

    useEffect(() => {
        request("/product/admin/category/", "GET", null, {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Accept-Language": 'ru'
        })
            .then(response => dispatch(getCategories(response.data)))
            .catch(err => console.log(err))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const deleteSelectedData = () => {
        request(id === 2 ? "/api/news/admin/news/delete/bulk" : id === 3 ? "/product/admin/delete/multiple/notisification/" : "", "DELETE", JSON.stringify({
            pk: selectedData
        }), {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            'Content-Type': 'application/json',
        })
            .then(response => {
                alert("Item deleted successfully!")
                dispatch(changeRandom(Math.floor(Math.random() * 100)))
                setSelectedData([])
            })
            .catch(err => console.log(err))
    };

    return (<div className="table-header">
        <input type="text" className="search-input" placeholder="Qidirish" onChange={(e) => dispatch(handleSearchValue(e.target.value))}/>
        <div className="sort-filter-wrapper">
            {
                selectedData.length > 0 &&
                <button className="delete_btn" onClick={deleteSelectedData}>
                    <img src={DeleteIcon} alt="delete_icon" />
                    <span>O`chirish</span>
                </button>
            }
            <FormControl sx={{ minWidth: 200 }} size="small">
                <InputLabel id="demo-select-small">Filtr</InputLabel>
                <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    label="Filtr"
                    sx={selectStyle}
                    value={filterItem === "clear" ? "" : filterItem}
                    onChange={(e) => handleFilterItem(e)}
                >
                    {
                        Object.keys(menuItems[id]).map(index =>
                            <MenuItem sx={menuItemStyle} value={menuItems[id][index].key}>{menuItems[id][index].value}</MenuItem>
                        )
                    }
                </Select>
            </FormControl>

            {
                typeOfData === 'AD' ?
                    <FormControl sx={{ minWidth: 150 }} size="small">
                        <InputLabel id="demo-select-small">Kategoriyalar</InputLabel>
                        <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            label="Kategoriyalar"
                            sx={selectStyle}
                            value={categoryFilterItem}
                            onChange={(e: any) => { dispatch(handleCategoryFilter(e.target.value as void)); dispatch(handleCurrentPage(1)) }}
                        >
                            {
                                categories?.map(item => (
                                    <MenuItem sx={menuItemStyle} value={`/${item.pk}`}>{item.name}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl> : null
            }
        </div>
    </div>

    )
}

export default HeaderComponent