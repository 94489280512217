import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import './warning.scss';

interface popsType {
    title: string,
    text: string,
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    purpose: any,
    id: number
}

const Warning = ({ title, text, open, setOpen, purpose, id }: popsType) => {

    return (
        <div className='warning'>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className="warning-popover">
                    <header className="warning-header">
                        <h3 className="warning-title">{title}</h3>
                        <CloseIcon style={{ cursor: "pointer" }} onClick={() => setOpen(false)} />
                    </header>
                    <body className="warning-body">
                        <h3 className="warning-title">{text}</h3>
                    </body>
                    <footer className="warning-footer">
                        <button className='warning-footer-cancel-btn' onClick={() => setOpen(false)} >
                            {
                                id === 2 ? "Отмена" : "Bekor qilish"
                            }
                        </button>
                        <button className='warning-footer-save-btn' onClick={purpose}>{
                                id === 2 ? "Выйти" : "O`chirish"
                            }</button>
                    </footer>
                </div>
            </Dialog>
        </div>
    )
}

export default Warning;