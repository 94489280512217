import { Switch } from "@mui/material";
import DeleteIcon from '../../../assets/svg/deleteIcon.svg'
import { useAppSelector } from "../../../hook/useTypedSelector";
import useFetch from "../../../hook/useFetch";
import { useEffect, useState } from "react";
import { changeRandom } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import Warning from "../../Warning/Warning";

const AnnouncesTable = () => {
    const [open, setOpen] = useState<boolean>(false);
    const [id, setId] = useState<number>()
    const [actives, setActives] = useState<number[]>([]);
    const { data } = useAppSelector(state => state);
    const { request } = useFetch();
    const dispatch = useDispatch();

    const handleChange = (id: number, status: string) => {
        if (actives?.includes(id)) setActives(init => init?.filter(item => item !== id));
        else setActives([...actives, id]);

        request(`/api/news/admin/v1/active/recommend/${id}`, "PUT", JSON.stringify({
            status: actives?.includes(id) ? 'inactive' : 'active',
        }), {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            'Content-Type': 'application/json',
        })
            .then(response => {
                if (response.success) {
                    dispatch(changeRandom(Math.floor(Math.random() * 100)))
                }
            })
            .catch(err => console.log(err))
    };

    const deleteItem = () => {
        request(`/api/news/admin/v1/active/recommend/${id}`, "DELETE", null, {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        })
            .then(response => {
                setOpen(false)
                dispatch(changeRandom(Math.floor(Math.random() * 100)))
            })
            .catch(err => console.log(err))
    };

    useEffect(() => {
        if (data) {
            const tempArray: number[] = [];
            data.forEach(({ pk, status }) => {
                if (status === 'active') {
                    tempArray.push(pk)
                }
            })
            setActives(tempArray)
        }
    }, [data])

    return (
        <>
            <tr>
                <th className="row-header">Nomer</th>
                <th className="row-header">Nomi</th>
                <th className="row-header">Boshlanish muddati</th>
                <th className="row-header">Tugash muddati</th>
                <th className="row-header">Status</th>
            </tr>
            {
                data.map(({ end_date, start_date, status, title, pk }) => (
                    <tr>
                        <td className="row-item item-id">{pk}</td>
                        <td className="row-item" >{title}</td>
                        <td className="row-item item-btn">{start_date}</td>
                        <td className="row-item">{end_date}</td>
                        <td className="row-item" style={{ color: actives?.includes(pk) ? 'green' : 'red' }}>{actives?.includes(pk) ? 'Yoqilgan' : 'O`chirilgan'}</td>
                        <td className="row-item">
                            <Switch
                                color="success"
                                checked={actives?.includes(pk)}
                                onChange={() => handleChange(pk, status!)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </td>
                        <td className="row-item">
                            <img onClick={() => { setOpen(true); setId(pk) }} style={{ cursor: 'pointer' }} src={DeleteIcon} alt="delete_icon" />
                        </td>
                    </tr>
                ))
            }

            <Warning
                id={1}
                purpose={deleteItem}
                title="Kategoriyani oʻchirish"
                text="Kategoriyani o'chirishda barcha ma'lumotlar va ichki toifalar o'chiriladi, ularni qayta tiklab bo'lmaydi"
                open={open}
                setOpen={setOpen}
            />
        </>
    )
}

export default AnnouncesTable