import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Hospital from '../../assets/svg/hospital.svg'
import { useAppSelector } from '../../hook/useTypedSelector';
import useFetch from '../../hook/useFetch';
import './internalNews.scss';
import { newsDetails } from '../../data/types';
import { useDispatch } from 'react-redux';
import { changeRandom } from '../../redux/actions';

const InternalNews = () => {
    const [newsDetails, setNewsDetails] = useState<newsDetails>();
    const navigate = useNavigate();
    const { newsId } = useParams();
    const { request } = useFetch();
    const dispatch = useDispatch();

    useEffect(() => {
        request(`/api/news/update/destroy/api/${newsId}`, "GET", null, {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        })
            .then(response => {
                if (response.success) {
                    setNewsDetails(response.data);
                }
            })
            .catch(err => console.log(err))
    }, [])

    const deleteNews = () => {
        request(`/api/news/update/destroy/api/${newsId}`, "DELETE", null, {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        })
            .then(() => {
                alert("Successfully deleted");
                dispatch(changeRandom(Math.floor(Math.random() * 100)))
                navigate('/news');
            })
            .catch(err => console.log(err))
    }

    return (
        <div className='internal-news'>
            <div>
                <h3 className="title" onClick={() => navigate('/news')}><ArrowBackIcon /> Новости</h3>
                <div className="main-internal-news">
                    <div className="additional-info">
                        <img src={newsDetails?.images} alt="additional-info" />
                    </div>
                    <div className="news-content">
                        <h3 className="news-title">{newsDetails?.theme}</h3>
                        <p className="news-text">{newsDetails?.body}</p>
                        <span className='news-date'>{newsDetails?.time}  |  {newsDetails?.date}</span>
                    </div>
                </div>
            </div>

            <div className="internal-news-footer">
                <button className='internal-news-footer-cancel-btn' onClick={deleteNews}>Удалить</button>
                <button className='internal-news-footer-save-btn' onClick={() => navigate(`/news/create-news/${newsId}`)}>Изменить</button>
            </div>
        </div>
    )
}

export default InternalNews