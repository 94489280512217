import React, { useEffect, useState } from 'react';
import SmallLoader from '../../components/SmallLoader/SmallLoader';
import Logo from '../../assets/svg/logo.svg';
import { base_url } from '../../base_url';
import './login.scss'

const Login = () => {
  const [tabId, setTabId] = useState(0);
  const [phone, setPhone] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [isLoading, setLoading] = useState(false);
  const [isCode, setIsCode] = useState<boolean>();
  const [verifyCode, setVerifyCode] = useState<string>();
  const [isActive, setActive] = useState<boolean>(false);
  const [count, setCount] = useState<number>(60);

  const handleLogin = () => {
    if (phone && password) {
      setLoading(true)
      fetch(`${base_url}/user/api/admin/`, {
        method: "POST",
        headers: {
          "Content-type": "application/json"
        },
        body: JSON.stringify({
          phone_number: `+${phone}`,
          password: password
        }),
      })
        .then((res) => res.json())
        .then(result => {
          setLoading(false)
          if (result.success === true) {
            setTabId(1);
            setActive(true);
          } else {
            alert("Введенная информация неверна");
          }
        }).catch((err) => {
          setLoading(false);
          alert("Error");
        });
    } else {
      alert("Пожалуйста, заполните необходимые данные");
    }
  }

  const handleVerify = () => {
    setLoading(true);
    fetch(`${base_url}/user/api/verify/otp/`, {
      method: "POST",
      headers: {
        "Content-type": "application/json"
      },
      body: JSON.stringify({
        phone_number: `+${phone}`,
        otp: verifyCode
      })
    })
      .then(response => response.json())
      .then((result) => {
        if (result.success === true) {
          localStorage.setItem("token", result.access);
          setTimeout(() => {
            window.location.reload();
            setLoading(false);
          }, 1000);
        } else {
          setLoading(false);
          setIsCode(false);
          alert("Введенная информация неверна");
        }
      })
      .catch((err) => {
        setLoading(false);
        alert("Error");
      });
  };

  const onKeyDownLogin = (e: any) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      handleLogin();
    }
  };

  const onKeyDownVerify = (e: any) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      handleVerify();
    }
  };

  // timer counter from 60 to 0
  useEffect(() => {
    let interval: any = null;
    if (isActive) {
      interval = setInterval(() => {
        setCount((count) => count - 1);
      }, 1000);
      if (count <= 0) {
        clearInterval(interval);
        setActive(false);
        setTabId(0);
        setCount(60);
      }
    }
    return () => clearInterval(interval);
  }, [isActive, count]);

  // phone input functions
  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.replace(/\D/g, "").length === 12) {
    } else {
      // setPhoneColor("default");
    }
    setPhone(event.target.value.replace(/\D/g, "").substring(0, 12));
  };

  console.log(tabId)

  return (
    <div className="login-page">
      <div className='logo-wrapper'>
        <img className='logo' src={Logo} alt="logo" />
        <div className="logo-title">
          <h1>ZIYOVUDDIN</h1>
          <span>Admin Panel</span>
        </div>
      </div>
      {tabId === 0 ? (
        <div className="login">
          <h1>Kirish</h1>
          <input
            className="phone-input"
            value={phone}
            type="number"
            placeholder="Telefon raqam (+9989..)"
            onChange={(event) => {
              handlePhoneChange(event);
            }}
          />
          <input
            className="password-input"
            value={password}
            type="password"
            placeholder="Parol"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            onKeyDown={onKeyDownLogin}
          />
          {isLoading ? (
            <button className="enter-btn enter-btn-loader">
              <SmallLoader />
            </button>
          ) : (
            <button className="enter-btn" onClick={() => handleLogin()}>
              Kirish
            </button>
          )}
        </div>
      ) : (
        <div className="password-message">
          <input
            style={{ display: "none" }}
            value={phone}
            type="number"
            onChange={(e) => {
              if (typeof e.target.value === 'number') {
                setPhone(e.target.value);
              }
            }}
          />
          <h1 className="confirm-title">Kodni tasdiqlash</h1>
          <p className="send-code">
            Yuqoridagi raqamga tasdiqlash kodi bilan SMS yubordik.
          </p>
          <p className="code-text">Kod</p>
          <div className="confirm-time">
            <input
              className="confirm-input"
              style={
                isCode === false ? { borderColor: "red", outline: "red" } : {}
              }
              value={verifyCode}
              type="password"
              placeholder="Kodni kiriting"
              onChange={(e) => {
                setVerifyCode(e.target.value);
              }}
              onKeyDown={onKeyDownVerify}
            />
            <div className={isActive ? `time` : `time inactive-time`}>
              {count}
            </div>
          </div>
          <div className="exclamation-point">
            <div className="exclamation">!</div>
          </div>
          <p>
            Agar bir daqiqa ichida tasdiqlash kodini olmagan boʻlsangiz, qayta urinib koʻring yoki telefon raqamingiz toʻgʻriligini tekshiring
          </p>
          <button className="try-again"  onClick={() => handleLogin()}>
            Kodni qayta jo`natish
          </button>
          {isLoading ? (
            <button className="enter-btn enter-btn-loader">
              <SmallLoader />
            </button>
          ) : (
            <button className="enter-btn" onClick={() => handleVerify()}>
              Tasdiqlash
            </button>
          )}
        </div>
      )}
    </div>
  )
}

export default Login