import Header from '../../components/Header.tsx/Header';
import Table from '../../components/Table/Table';
import './users.scss'

const Users = () => {
  return (
    <div className='users'>
      <Header title="Foydalanuvchilar" />
      <Table id={1} />
    </div>
  )
}

export default Users