import { useEffect, useState, } from "react";
import { useAppSelector } from "../../hook/useTypedSelector";
import { useDispatch } from "react-redux";
import { handleCurrentPage, handlePerPage, setFilter } from "../../redux/actions";
import AdTable from "./tableComponents/AdTable";
import UsersTable from "./tableComponents/UsersTable";
import NewsTable from "./tableComponents/NewsTable";
import NotificationsTable from "./tableComponents/NotificationsTable";
import './table.scss';
import PaginationComponent from "./components/PaginationComponent";
import HeaderComponent from "./components/HeaderComponent";
import AnnouncesTable from "./tableComponents/AnnouncesTable";

const Table = ({ id }: { id: number }) => {
    const [perPage, setPerPage] = useState<number>(10);
    const { totalPages, currentPage, typeOfData, searchValue } = useAppSelector(state => state);
    const dispatch = useDispatch();
    const [selectedData, setSelectedData] = useState<number[]>([]);

    const handleChangePerPage = (event: any) => {
        if (typeof event.target.value === 'number') {
            setPerPage(event.target.value);
            dispatch(handlePerPage(event.target.value))
            setCurrentPage(1)
        }
    };

    const setCurrentPage = (page: number) => {
        dispatch(handleCurrentPage(page))
    };

    const handleFilterItem = (e: any) => {
        dispatch(setFilter(e.target.value as string))
        setCurrentPage(1)
    };

    useEffect(() => {
        setSelectedData([])
    }, [id]);

    return (
        <div className="table">

            <HeaderComponent id={id} handleFilterItem={handleFilterItem} setSelectedData={setSelectedData} selectedData={selectedData} />


            <table className="table-body">
                {
                    typeOfData === 'AD' ?
                        <AdTable />
                        : typeOfData === 'USERS' ? <UsersTable />
                            : typeOfData === 'NEWS' ? <NewsTable selectedData={selectedData} setSelectedData={setSelectedData} />
                                : typeOfData === 'NOTIFICATIONS' ? <NotificationsTable selectedData={selectedData} setSelectedData={setSelectedData} /> 
                                    : <AnnouncesTable />
                }
            </table>
            {
                searchValue.length < 2 ?
                    <PaginationComponent currentPage={currentPage} totalPages={totalPages} perPage={perPage} handleChangePerPage={handleChangePerPage} setCurrentPage={setCurrentPage} /> : null
            }
        </div>
    )
}

export default Table