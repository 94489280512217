import { menuItemsType } from "./types";

export const menuItems: menuItemsType = {
    0: {
        1: {
            key: '/inactive',
            value: 'Ko`rilmagan'
        },
        2: {
            key: '/published',
            value: 'Qabul qilingan'
        },
        3: {
            key: '/rejected',
            value: 'Bekor qilingan'
        },
    },
    1: {
        1: {
            key: '/premium',
            value: 'Premium'
        },
        2: {
            key: '/free',
            value: 'Bepul'
        },
        3: {
            key: '/turbo',
            value: 'Turbo'
        }
    },
    2: {
        1: {
            key: '/new',
            value: 'Yangi'
        },
        2: {
            key: '/old',
            value: 'Eski'
        }
    },
    3: {
        1: {
            key: '/new',
            value: 'Yangi'
        },
        2: {
            key: '/old',
            value: 'Eski'
        }
    },
    4: {
        1: {
            key: '/active',
            value: 'Aktiv'
        },
        2: {
            key: '/inactive',
            value: 'Aktivsiz'
        }
    }
};