import Header from '../../components/Header.tsx/Header';
import Table from '../../components/Table/Table';
import './notifications.scss'

const Notifications = () => {
  return (
    <div className='notifications'>
      <Header title="Eslatmalar" addItemBtn={{ isExecuted: true, text: "Создать новый" }} />
      <Table id = {3}/>
    </div>
  )
}

export default Notifications