import { base_url } from "../base_url";
import { useCallback } from "react";
import { useState } from "react";
import { body, method } from "../data/types";

const useFetch = () => {
    const [status, setStatus] = useState();

    const request = useCallback(async (url: string, method: method, body: any = null, headers:any = null) => {
        try {
            const response = await fetch(`${base_url}${url}`, {
                method,
                headers,
                body,
            });
            if (!response.ok) {
                if(response.status === 401) {
                    localStorage.clear()
                }
                // setStatus(response);
                throw new Error(`Could not fetch ${url}, status ${response.status}`);
            }
            const data = await response.json();
            return data;

        } catch (err) {
            console.log(err)
        }
    }, [])
    return { request, status };
}

export default useFetch;