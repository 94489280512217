import { createAction } from "@reduxjs/toolkit";
import { typeOfData, category, newsTable } from "../data/types";

export const changeRandom = createAction<number>("HANDLE_EFFECT");
export const handleData = createAction<newsTable[]>("HANDLE_DATA");
export const handleTypeOfData = createAction<typeOfData>("HANDLE_TYPE_OF_DATA");
export const handleCurrentPage = createAction<number>("HANDLE_CURRENT_PAGE");
export const handleTotalPages = createAction("HANDLE_TOTAL_PAGES");
export const handlePerPage = createAction<number>("HANDLE_PER_PAGE");
export const getCategories = createAction<category[]>("GET_CATEGORIES");
export const setFilter = createAction<string>("SET_FILTER");
export const handleCategoryFilter = createAction("CATEGORY_FILTER");
export const handleSearchValue = createAction<string>("HANDLE_SEARCH_VALUE");
export const getRole = createAction<'admin' | 'superAdmin'>("GET_ROLE");