import * as React from 'react';
import { useState } from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import Avatar from '../../assets/svg/avatar.svg';
import "swiper/css";
import "swiper/css/navigation";
import { modalStyle } from '../../data/styles';
import './modal.scss';
import { adExactDataType } from '../../data/types';
import { base_url } from '../../base_url';
import useFetch from '../../hook/useFetch';
import ClearIcon from '@mui/icons-material/Clear';


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function TransitionsModal({ open, setOpen, data }: { open: boolean, setOpen: React.Dispatch<React.SetStateAction<boolean>>, data: adExactDataType | null }) {
    const handleClose = () => setOpen(false);
    const { request } = useFetch();
    const [cause, setCause] = useState<string>('');

    const announce = data?.announce[0];
    const params = data?.params;
    const images = announce?.images && JSON.parse(announce?.images);

    console.log(params);

    const changeTypeAd = (id: number) => {
        if (id === 0 || (id === 1 && cause)) {
            request(`/product/admin/update/status/${announce?.pk}`, "PUT", JSON.stringify({
                status: id === 0 ? "published" : "rejected",
                notisification: id === 0 ? "published" : cause
            }), {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                'Content-Type': 'application/json',
            })
                .then(data => {
                    if (data.success) {
                        alert(id === 0 ? "Published" : "Rejected")
                        setOpen(false)
                        setOpenDialog(false)
                        window.location.reload();
                    }
                })
                .catch(err => console.log(err))
        } else {
            alert("please select cause of rejection.")
        }
    }

    const [openDialog, setOpenDialog] = React.useState(false);

    const handleClickOpen = () => {
        setOpenDialog(true);
        console.log("sefew")
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setCause('published');
    };

    return (
        <>{
            announce &&
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <div className="modal-body">
                        <div className="modal-left-side">
                            <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
                                {
                                    images && images.map((item: string, index: number) => (
                                        <SwiperSlide className="mySwiper-slide" key={index}><img src={`${base_url}${item}`} alt="" /></SwiperSlide>
                                    ))
                                }
                            </Swiper>
                            <div className="ad-user-info">
                                <h3 className="ad-user-info-title">Foydalanuvchi</h3>
                                <div className="ad-user-info-body">
                                    <div className="ad-user-img-wrapper">
                                        <img alt="avatar" className="ad-user-img" src={announce.user_image ? `${base_url}${announce.user_image}` : Avatar}></img>
                                    </div>
                                    <div className="ad-user-profile">
                                        <h4 className="ad-user-profile-title">Profil</h4>
                                        <h4 className="ad-user-name">{announce.full_name || 'No name'}</h4>
                                        <span className="ad-user-phone">{announce.phone_number || "No number"}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="ad-user-tariff">
                                <h3 className="ad-user-tariff-title">Tarif</h3>
                                <div className="ad-user-tariff-info">
                                    <span className="ad-user-tariff-name">{announce.user_tarif}</span>
                                    <h3 className="ad-user-tariff-cost">15,000 so`m</h3>
                                    <span>E`lon yuqorida 7 kun davomida turadi.</span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-right-side">
                            <h3 className="product-name">{announce.title}</h3>
                            <span className="product-cost">{announce.price} UZS</span>
                            <div className="about-product">
                                <h3 className="about-product-title">Sarlavha</h3>
                                <p className="about-product-text">
                                    {announce.body || "NO TEXT"}
                                </p>
                            </div>
                            <div className="character-product">
                                <h3 className="charecter-product-tite">Parametrlari</h3>
                                {
                                    params ? params.integer[0]?.map(item => (
                                        <div className="product-character-item">
                                            <span className='character-type'>{item.params_id__name}</span>
                                            <span className='character-type-value'>{item.integer}</span>
                                        </div>
                                    )) : null
                                }

                                {
                                    params ? params.string?.map(item => (
                                        <div className="product-character-item">
                                            <span className='character-type'>{item.params_id__name}</span>
                                            <span className='character-type-value'>{item.string}</span>
                                        </div>
                                    )) : null
                                }

                                {
                                    params ? params.selection?.map(item => (
                                        <div className="product-character-item">
                                            <span className='character-type'>{item.name}</span>
                                            <div className="character-selection-type-value-wrapper">
                                                {
                                                    item.values?.map(item => (item.selecttion__value)).join()
                                                        // <span className='character-selection-type-value'>{item.selecttion__value}</span>
                                                }
                                            </div>
                                        </div>
                                    )) : null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className='modal-footer-cancel-btn' onClick={handleClickOpen}>Bekor qilish</button>
                        <button className='modal-footer-save-btn' onClick={() => changeTypeAd(0)}>Qabul qilish</button>
                    </div>
                    <Dialog
                        open={openDialog}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseDialog}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <div className="rejection-cause">
                            <header className="rejection-cause-header">
                                <div className="rejection-cause-title">Bekor qilish sababini ko'rsating</div>
                                <ClearIcon onClick={() => setOpenDialog(false)} />
                            </header>

                            <div className="cause-list">
                                <div className="cause-item-wrapper">
                                    <input onChange={(e) => setCause(e.target.value)} type="radio" id="Неудобно пользоваться" name='cause-item' value="Неудобно пользоваться" />
                                    <label htmlFor="Неудобно пользоваться">Axloq qoidalariga zid.</label>
                                </div>

                                <div className="cause-item-wrapper">
                                    <input onChange={(e) => setCause(e.target.value)} type="radio" id="Много занимает времени" name='cause-item' value="Много занимает времени" />
                                    <label htmlFor="Много занимает времени">Ko'p vaqt talab etadigan e`lon.</label>
                                </div>

                                <div className="cause-item-wrapper">
                                    <input onChange={(e) => setCause(e.target.value)} type="radio" id="Политика конфиденциальности" name='cause-item' value="Политика конфиденциальности" />
                                    <label htmlFor="Политика конфиденциальности">Maxfiylik siyosatiga mos emas.</label>
                                </div>

                                <div className="cause-item-wrapper">
                                    <input onChange={(e) => setCause(e.target.value)} type="radio" id="Сервисы не удовлетворили" name='cause-item' value="Сервисы не удовлетворили" />
                                    <label htmlFor="Сервисы не удовлетворили">Xizmat qoniqarli emas</label>
                                </div>

                                <div className="cause-item-wrapper">
                                    <input onChange={(e) => setCause(e.target.value)} type="radio" id="Другое" name='cause-item' value="Другое" />
                                    <label htmlFor="Другое">Boshqa sabab.</label>
                                </div>
                            </div>

                            <footer>
                                <button className='send-feedback' onClick={() => changeTypeAd(1)}>Jo`natish</button>
                            </footer>
                        </div>
                    </Dialog>
                </Box>
            </Modal>
        }

        </>

    );
}