import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../hook/useTypedSelector';

const NewsTable = ({ selectedData, setSelectedData }: { selectedData: number[], setSelectedData: Dispatch<SetStateAction<number[]>> }) => {
    const { data } = useAppSelector(state => state);
    const navigate = useNavigate();

    const handleSelectedData = ({ e, pk, all }: { e?: any, pk?: number, all?: boolean }) => {
        if (all) {
            if (selectedData.length === data.length) {
                setSelectedData([])
            } else {
                setSelectedData(data.map(({ pk }) => pk))
            }
        } else if (!all && pk && e) {
            if (e.target.checked) {
                setSelectedData(init => [...init, pk])
            } else {
                setSelectedData((init) => init.filter(id => id !== pk))
            }
        }
    };

    return (
        <>
            <tr>
                <th>
                    <input
                        checked={selectedData.length === data.length}
                        onChange={() => handleSelectedData({ all: true })}
                        type="checkbox" name="notifications" id="" 
                        style={{ transform: 'scale(1.5)', cursor: 'pointer' }} 
                    />
                </th>
                <th className="row-header">Yangilik sarlavhasi</th>
                <th className="row-header">Sana / Vaqt</th>
                <th className="row-header">Ko`rishlar soni</th>
            </tr>
            {
                data?.map(({ pk, date, viewers_count, theme, time }) => (
                    <tr key={pk}>
                        <td className="row-item item-id">
                            <input
                                type="checkbox"
                                name="news" id=""
                                checked={selectedData.includes(pk)}
                                style={{ transform: 'scale(1.5)', cursor: 'pointer' }}
                                onChange={(e) => handleSelectedData({ e, pk })}
                            />
                        </td>
                        <td className="row-item news-limit-length" onClick={() => navigate(`internal-news/${pk}`)}>{theme} </td>
                        <td className="row-item item-btn">{date}/{time}</td>
                        <td className="row-item item-btn">{viewers_count} просмотрев</td>
                    </tr>
                ))
            } 

        </>
    )
}

export default NewsTable