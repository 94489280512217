export const selectStyle = {
  borderRadius: "10px",
  backgroundColor: "white",
  color: "rgba(0, 0, 0, 0.87)",
}

export const menuItemStyle = {
  color: "#000",
  display: "flex",
  gap: "5px",

  "&:nth-of-type(2n)": {
    backgroundColor: "rgba(224, 224, 224, 0.2)",
  },

  "&.Mui-selected": {
    backgroundColor: "rgba(128, 128, 128, 0.2) !important",

    "&:hover, &:focus": {
      backgroundColor: "rgba(128, 128, 128, 0.4)",
    },
  },

  "&:hover, &:focus": {
    backgroundColor: "rgba(224, 224, 224, 0.8)",
  },

  " .menu-icon": {
    width: "24px",
    height: "24px",
    color: "#a0a0a0",
    fill: "#a0a0a0",

    "&.like-active": {
      color: "#1db94e",
    },

    "&.share": {
      transform: "scale(-1, 1)",
    },
  },
};

export const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '860px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  outline: 'none',
  borderRadius: "10px",
}

export const PopperStyle = {
  zIndex: 9999,
  top: '50% !important',
  left: '50% !important',
  width: '500px',
  transform: 'translate(-50%, -50%)',
}

export const selectBranch = {
  width: "100%",
  borderRadius: "10px",
  backgroundColor: "white",
  color: "rgba(0, 0, 0, 0.87)",
  ".css-78me8v-MuiFormControl-root": {
    margin: '0px'
  }
}

export const menuItemBranch = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "div": {
    display: 'flex',
    alignItems: "center",
    gap: '20px'
  },

  ".MuiCheckbox-root": {
    padding: '0px', 
  }
}

export const accordionStyle = {
  borderBottom: '1px solid rgba(128, 128, 128, 0.4)',
  ".MuiAccordionSummary-content": {
    justifyContent: "space-between",
    ".css-1pnmrwp-MuiTypography-root" : {
      marginRight: '20px',
      display: "flex",
      alignItems: 'center',
      gap: '10px'
    }
  }
}