import Header from '../../components/Header.tsx/Header';
import Table from '../../components/Table/Table';
import './news.scss'

const News = () => {
  return (
    <div className='news'>
      <Header title="Yangiliklar" addItemBtn={{ isExecuted: true, text: "Добавить новый" }} />
      <Table id = {2} />
    </div>
  )
}

export default News