import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Fade, Popper } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import './internalCategory.scss';
import useFetch from '../../hook/useFetch';
import { category, parametr, subCategory } from '../../data/types';
import { base_url } from '../../base_url';
import { accordionStyle, menuItemStyle, PopperStyle, selectBranch } from '../../data/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DeleteIcon from '../../assets/svg/deleteIcon.svg';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAppSelector } from '../../hook/useTypedSelector';

const InternalCategory = () => {
    const [subcategories, setSubcategories] = useState<subCategory[] | null>(null);
    const [parametres, setParametres] = useState<parametr[] | null>(null);
    const [idPopper, setIdPopper] = useState<number>(0);
    const [popoverInput, setPopoverInput] = useState<string>('');
    const [category, setCategory] = useState<category | null>(null);
    const [img, setImg] = useState<any>();
    const [selectedRadio, setSelected] = useState<string>('string');
    const [newCategoryName, setNewCategoryName] = useState<string>('');
    const [selectedSubcategory, setSelectedSubcategory] = useState<number | undefined>();
    const [paramValues, setParamValues] = useState<string[]>([]);
    const [paramValueName, setParamValueName] = useState<string>('');
    const [expanded, setExpanded] = useState<string | false>(false);
    const [popupText, setPopupText] = useState({
        title: '',
        inputTitle: '',
        inputPlaceholder: '',
        inputType: false
    })
    const [render, setRender] = useState<boolean>(false)
    const [open, setOpen] = useState(false);
    const { categoryId } = useParams();
    const { role } = useAppSelector(state => state)
    const { request } = useFetch();
    const navigate = useNavigate();

    const RadioInputs = [
        { text: 'Matn', value: 'string' },
        { text: 'Raqam', value: 'integer' },
        { text: 'bir tanlovli', value: 'select' },
        { text: 'Ko`p tanlovli', value: 'multiselect' },
    ]

    const addSubCategoryParametr = () => {
        if (popoverInput) {

            (!popupText.inputType ?
                request("/product/admin/subcategory/", "POST", JSON.stringify({
                    parent: categoryId,
                    name: popoverInput
                }), {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Accept-Language": 'ru'
                })
                :
                request("/product/admin/create/params/by/v1/value", "POST", JSON.stringify(
                    selectedRadio === 'select' || selectedRadio === 'multiselect' ?
                        {
                            subcategory: selectedSubcategory,
                            name: popoverInput,
                            type_of_value: selectedRadio,
                            select_values: paramValues,
                        } : {
                            subcategory: selectedSubcategory,
                            name: popoverInput,
                            type_of_value: selectedRadio,
                        }), {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Accept-Language": 'ru'
                }))

                .then(response => {
                    if (response.success) {
                        setRender(!render)
                        setOpen(false);
                        setPopoverInput('');
                        setParamValues([])
                    }
                })
                .catch(err => console.log(err))
        } else {
            alert("Please fill input!")
        }
    }

    const cancelOrder = () => {
        setOpen(false);
        setPopoverInput('');
        setParamValues([])
    }

    const deleteSubcategory = (id: number) => {
        request(`/product/admin/subcategory/${id}`, "DELETE", undefined, {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        })
            .then(response => {
                setRender(!render)
            })
            .catch(err => console.log(err))
    };

    const deleteParametr = (id: number) => {
        request(`/product/admin/delete/params/${id}`, "DELETE", undefined, {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        })
            .then(response => {
                setRender(!render)
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        request(`/product/admin/subcategory/list/bycategory/${categoryId}`, "GET", null, {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Accept-Language": 'ru'
        })
            .then(response => {
                setSubcategories(response.sublist); setCategory({
                    pk: response.category[0].pk,
                    name: response.category[0].name,
                    icon: `${base_url}${response.category[0].icon}`
                })
            })
            .catch(err => console.log(err))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryId, render])

    useEffect(() => {
        console.log("fucking working")
        if (selectedSubcategory) {
            request(`/product/admin/get/list/v2/params/${selectedSubcategory}`, "GET", null, {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            })
                .then(response => {
                    console.log(response);
                    setParametres([...response?.input_value, ...response?.multiselect, ...response?.select]);
                })
                .catch(err => console.log(err))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSubcategory, render])

    const addBranchSubcategory = (id: number) => {
        setIdPopper(0);

        switch (id) {
            case 0:
                setSelected('string');
                setOpen(true);
                setPopupText({
                    title: 'Podkategoriya qo`shish',
                    inputTitle: "Podkategoriya nomi",
                    inputPlaceholder: 'Podkategoriya nomini qo`shing',
                    inputType: false,
                })
                break;
            case 1:
                if (selectedSubcategory === undefined) {
                    alert('Iltimos, avval podkategoriyani tanlang')
                } else {
                    setOpen(true);
                    setPopupText({
                        title: 'Parametr qo`shish',
                        inputTitle: "Parametr nomi",
                        inputPlaceholder: 'Parametr nomini kiriting',
                        inputType: true,
                    })
                }
                break;
            default:
                break;
        }
    }

    const changeCategory = () => {
        if (img && newCategoryName !== category?.name) {
            let formData = new FormData();

            formData.append('name', newCategoryName);
            formData.append('icon', img, img.name);

            request(`/product/admin/category/${categoryId}/`, "PUT", formData, {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            })
                .then(response => {
                    if (response.success) {
                        alert("Changed succesfully")
                        setRender(!render)
                    }
                })
                .catch(err => console.log(err))
        } else {
            alert("Iltimos rasm va nom o`zgarganligini tekshiring")
        }
    }

    const handleOpenChangeCategoryPopover = () => {
        setOpen(true);
        setIdPopper(1);
        setNewCategoryName(category?.name!);
    }

    const addParamValue = (item: string) => {
        if (popoverInput) {
            setParamValues([...paramValues, item]);
            setParamValueName('');
        } else {
            alert("Please enter name of the parametr first.");
            setParamValueName('');
        }
    };

    const deleteParamValue = (item: string) => {
        const tempArr = paramValues.filter(element => element !== item);
        setParamValues(tempArr)
    };


    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    return (
        <div className="internal-category">
            <div>
                <h3 className="title" onClick={() => navigate('/category')}><ArrowBackIcon /> {category?.name}</h3>
                <div className='internal-category-main-wrapper'>
                    <div className="category-collection">
                        <div className="category">
                            <div className="internal-category-image">
                                <img src={category?.icon} alt="" />
                            </div>
                            <div className="internal-category-info">
                                <h3 className="internal-category-name">{category?.name}</h3>
                                {
                                    role === 'superAdmin' ?
                                        <button className="change-category-info-btn" onClick={handleOpenChangeCategoryPopover}>O'zgartirish</button>
                                        : null
                                }

                            </div>
                        </div>
                        <div className="subcategory">
                            <header className='subcategory-header'>
                                <h3 className="subcategory-title">Pod kategoriyalar</h3>
                                <button onClick={() => addBranchSubcategory(0)} className="subcategory-btn">+ Yangi pod kategoriya qo`shish</button>
                            </header>
                            <body className='subcategory-body'>
                                {
                                    subcategories?.map(item => (
                                        <div key={item.pk} className="subcategory-item">
                                            <span className="subcategory-name">{item.name}</span>
                                            {
                                                role === 'superAdmin' ?
                                                    <img src={DeleteIcon} alt="delete_icon" style={{ cursor: 'pointer' }} onClick={() => deleteSubcategory(item.pk)} />
                                                    : null
                                            }

                                        </div>
                                    ))
                                }
                            </body>
                        </div>
                    </div>

                    <div className="branch">
                        <h3 className="branch-title">Podkategoriya qo`shish</h3>
                        <div className="branch-subcategory-wrapper">
                            <h3 className="select-subcategory-title">Podkategoriyalar</h3>
                            <FormControl fullWidth sx={{ mt: 3 }}>
                                <InputLabel id="demo-simple-select-label">Podkategoriya tanlash</InputLabel>
                                <Select
                                    sx={selectBranch}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedSubcategory}
                                    label="Podkategoriya tanlash"
                                    onChange={(e) => { if (typeof e.target.value === 'number') setSelectedSubcategory(e.target.value) }}
                                >{
                                        subcategories?.map(item => (
                                            <MenuItem key={item.pk} sx={menuItemStyle} value={item.pk}>{item.name}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                            <div className='add-branch'>
                                <header className='add-branch-header'>
                                    <h3 className="add-branch-title">Parametrlar</h3>
                                    <button onClick={() => addBranchSubcategory(1)} className="add-branch-btn">+ Parametr qo`shish</button>
                                </header>
                                <div className="branch-list">
                                    {
                                        parametres?.map(item => item.input_type === 'string' || item.input_type === 'integer' ?
                                            (
                                                <div key={item.pk} className="branch-item">
                                                    <div className="branch-name-type-wrapper">
                                                        <span className="branch-name">{item.name}</span>
                                                        <div className='type-parametr'>
                                                            <span>{item.input_type}</span>
                                                        </div>
                                                    </div>
                                                    {
                                                        role === 'superAdmin' ?
                                                            <img
                                                                src={DeleteIcon}
                                                                alt="delete_icon"
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() => deleteParametr(item.pk)}
                                                            />
                                                            : null
                                                    }
                                                </div>
                                            ) : (
                                                <div className='accordion-wrapper'>
                                                    <Accordion expanded={expanded === `panel${item.pk}`} onChange={handleChange(`panel${item.pk}`)}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1bh-content"
                                                            id="panel1bh-header"
                                                            sx={accordionStyle}
                                                        >
                                                            <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                                                {item.name}
                                                            </Typography>
                                                            <Typography sx={{ color: 'text.secondary' }}>
                                                                {item.input_type}

                                                            </Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            {
                                                                item.values?.map(item => (
                                                                    <span className='param-value-item'>{item.value}</span>
                                                                ))
                                                            }
                                                        </AccordionDetails>
                                                    </Accordion>
                                                    {
                                                        role === 'superAdmin' ?
                                                            <img
                                                                src={DeleteIcon}
                                                                alt="delete_icon"
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() => deleteParametr(item.pk)}
                                                            />
                                                            : null
                                                    }
                                                </div>
                                            )
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="subcategory-footer">
                <button className='subcategory-footer-cancel-btn' onClick={() => navigate('/category')}>Отменить</button>
                <button className='subcategory-footer-save-btn' onClick={() => navigate('/category')}>Сохранить изменения</button>
            </div>
            <Popper sx={PopperStyle} open={open} transition>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        {
                            idPopper === 0 ?
                                <div className="add-subcategory-popover">
                                    <header className="add-subcategory-header">
                                        <h3 className="add-subcategory-title">{popupText.title}</h3>
                                        <CloseIcon style={{ cursor: "pointer" }} onClick={cancelOrder} />
                                    </header>
                                    <body className="add-subcategory-body">
                                        {
                                            popupText.inputType ?
                                                <div className='select-input-type'>
                                                    {
                                                        RadioInputs.map(item => (
                                                            <>
                                                                <input
                                                                    checked={selectedRadio === item.value}
                                                                    onChange={(e) => setSelected(e.target.value)}
                                                                    type="radio" id={item.value}
                                                                    name="select-input-type"
                                                                    value={item.value}
                                                                />
                                                                <label htmlFor={item.value}>{item.text}</label>
                                                            </>
                                                        ))
                                                    }
                                                </div>
                                                : null
                                        }
                                        <h3 className="add-subcategory-title">{popupText.inputTitle}</h3>
                                        <input
                                            type="text"
                                            onChange={(e) => setPopoverInput(e.target.value)}
                                            value={popoverInput}
                                            className="category-name-input"
                                            placeholder={popupText.inputPlaceholder}
                                        />
                                        {
                                            (selectedRadio === 'select' || selectedRadio === 'multiselect') ?
                                                <>
                                                    <h3 className="add-subcategory-item">Select Item</h3>
                                                    <div className="select-input-wrapper">
                                                        <input
                                                            type="text"
                                                            onChange={(e) => setParamValueName(e.target.value)}
                                                            value={paramValueName}
                                                            className="param-value-name-input"
                                                            placeholder={popupText.inputPlaceholder}
                                                        />
                                                        <button className='add-param-value-btn' onClick={() => addParamValue(paramValueName)}>Add</button>
                                                    </div>
                                                    {
                                                        paramValues.map(item => (
                                                            <div className='param-value-wrapper'>
                                                                <span className='param-value-item'>{item}</span>
                                                                <img onClick={() => deleteParamValue(item)} src={DeleteIcon} alt="delete_icon" />
                                                            </div>
                                                        ))
                                                    }
                                                </>
                                                : null
                                        }
                                    </body>
                                    <footer className="add-subcategory-footer">
                                        <button className='add-subcategory-footer-cancel-btn' onClick={cancelOrder}>Bekor qilish</button>
                                        <button className='add-subcategory-footer-save-btn' onClick={addSubCategoryParametr}>Qo`shish</button>
                                    </footer>
                                </div> :
                                <div className='change-category-popover'>
                                    <header className="change-category-popover-header">
                                        <h3 className="change-category-popover-title">Kategoriyani o`zgartirish</h3>
                                        <CloseIcon style={{ cursor: "pointer" }} onClick={cancelOrder} />
                                    </header>
                                    <div className="change-category-popover-main">
                                        <div className="change-category-popover-img-wrapper">
                                            <div className='category-img-wrapper'>
                                                <img src={img ? URL.createObjectURL(img) : category?.icon} alt="icon" />
                                            </div>
                                            <label htmlFor='image'>Rasm tanlash</label>
                                            <input onChange={e => {
                                                if (e.target.files) {
                                                    setImg(e.target.files[0])
                                                }
                                            }
                                            } type="file" id='image' accept="image/png, image/svg, image/jpeg" />
                                        </div>
                                        <div className="change-category-popover-name-wrapper">
                                            <h3 className="change-category-popover-name-title">Kategoriya nomi</h3>
                                            <input type="text" placeholder='Joriy kategoriya nomini o`zgartiring' className="change-category-popover-name-input" onChange={(e) => setNewCategoryName(e.target.value)} value={newCategoryName} />
                                        </div>
                                    </div>
                                    <footer className="change-category-footer">
                                        <button className='change-category-footer-cancel-btn' onClick={cancelOrder}>Bekor qilish</button>
                                        <button className='change-category-footer-save-btn' onClick={changeCategory}>O`zgartirish</button>
                                    </footer>
                                </div>
                        }
                    </Fade>
                )}
            </Popper>
        </div>
    )
}

export default InternalCategory