import {configureStore} from "@reduxjs/toolkit"
import { useDispatch } from "react-redux";
import { stringMiddleWare } from "./middleware/stringMiddleWare";
import {reducer} from "./redux/reducer";

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(stringMiddleWare),
  devTools: process.env.NODE_ENV !== "production"
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch: () => AppDispatch = useDispatch;