import Header from '../../components/Header.tsx/Header';
import Table from '../../components/Table/Table';
import './ad.scss'

const Ad = () => {
  return (
    <div className='ad'>
      <Header title="E`lonlar" />
      <Table  id={0} />
    </div>
  )
}

export default Ad