import Header from '../../components/Header.tsx/Header';
import UploadIcon from '../../assets/svg/upload_icon.svg';
import './category.scss';
import CategoryCollection from '../../components/CategoryCollection/CategoryCollection';
import { useEffect, useState } from 'react';
import useFetch from '../../hook/useFetch';
import { useAppDispatch } from '../../configStore';
import { getCategories } from '../../redux/actions';
import { useAppSelector } from '../../hook/useTypedSelector';


const Category = () => {
  const [name, setName] = useState<string>();
  const [img, setImg] = useState<any>();
  const [render, setRender] = useState<boolean>(false)
  const { request } = useFetch();
  const dispatch = useAppDispatch();
  const { categories } = useAppSelector(state => state)

  const createCategory = () => {
    if (name && img) {
      let formData = new FormData();

      formData.append('icon', img, img.name);
      formData.append('name', name);

      request("/product/admin/category/", "POST", formData, {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Accept-Language": 'ru'
      })
        .then(result => {
          setName('');
          setImg(null);
          setRender(!render)
        })
        .catch(err => console.log(err))
    }
  };

  useEffect(() => {
    request("/product/admin/category/", "GET", null, {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Accept-Language": 'ru'
    })
      .then(response => dispatch(getCategories(response.data)))
      .catch(err => console.log(err))
  }, [render])

  return (
    <div className='category'>
      <Header title="Kategoriyalar" />
      <div className="category-collection-wrapper">
        <CategoryCollection render={render} setRender={setRender} categories={categories} />
        <div className="add-category">
          <div className="header">
            <h3 className="title">Kategoriya qo`shish</h3>
            <button onClick={createCategory} className={name && img ? "add-category-btn exist-btn" : "add-category-btn"}>Qo`shish</button>
          </div>
          <div className="add-category-main">
            <input value={name} onChange={(e) => setName(e.target.value)} className="name-category-input" placeholder='Kategoriya nomini kiriting' />
            <div className="category-image">
              <h3 className="image-category-title">Rasm</h3>
              {
                img ?
                <div className="uploaded_img_wrapper">
                  <img className='uploaded-image' src={URL.createObjectURL(img)} alt="uploaded_image" /> 
                  <button className='remove-selected-img-btn' onClick={() => setImg(undefined)}>Rasmni almashtirish</button>
                </div>:
                  <label htmlFor='image'>
                    <img src={UploadIcon} alt="upload_icon" />
                    <p>Rasm qo`shish</p>
                  </label>
              }
              <input onChange={e => {
                if (e.target.files) {
                  setImg(e.target.files[0])
                }
              }
              } type="file" id='image' accept="image/*" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Category