import { useEffect, useState } from 'react';
import { Link, NavLink } from "react-router-dom";
import TimelineIcon from '@mui/icons-material/Timeline';
import LayersIcon from '@mui/icons-material/Layers';
import WindowIcon from '@mui/icons-material/Window';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import NotificationsIcon from '@mui/icons-material/Notifications';
import useFetch from '../../hook/useFetch';
import UserIcon from '../../assets/svg/user.svg';
import CampaignIcon from '@mui/icons-material/Campaign';
import LogoutIcon from '@mui/icons-material/Logout';

import "./asideBar.scss";
import { typeOfData } from "../../data/types";
import { useDispatch } from "react-redux";
import { handleCurrentPage, handleTypeOfData, setFilter } from "../../redux/actions";
import Warning from '../Warning/Warning';

const AsideBar = () => {
  const [open, setOpen] = useState<boolean>(false)
  const { request } = useFetch();
  const dispatch = useDispatch();
  const [userData, setUserData] = useState<{
    birth_date: string,
    full_name: string,
    image: string,
    phone_number: string,
  }>()
  const changeTypeOfData = (type: typeOfData) => {
    dispatch(handleCurrentPage(1));
    dispatch(setFilter("clear"));

    switch (type) {
      case "AD":
        dispatch(handleTypeOfData("AD"))
        localStorage.setItem("typeOfData", 'AD')
        break;
      case "USERS":
        dispatch(handleTypeOfData("USERS"))
        localStorage.setItem("typeOfData", 'USERS')
        break;
      case "NEWS":
        dispatch(handleTypeOfData("NEWS"))
        localStorage.setItem("typeOfData", 'NEWS')
        break;
      case "ANNOUNCES":
        dispatch(handleTypeOfData("ANNOUNCES"))
        localStorage.setItem("typeOfData", 'ANNOUNCES')
        break;
      default:
        dispatch(handleTypeOfData("NOTIFICATIONS"))
        localStorage.setItem("typeOfData", 'NOTIFICATIONS')
        break;
    }
  };

  const Logout = () => {
    localStorage.clear();
    window.location.reload()
  }

  useEffect(() => {
    if (localStorage.getItem("token")) {
      request(`/user/api/getuserinfo`, "GET", null, {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      })
        .then(response => {
          if (response.success) {
            setUserData(response.userdata)
          }
        })
        .catch(err => console.log(err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="nav">
      <Link to="/">
        <h1 className="logo-title">Admin Panel</h1>
      </Link>
      <div className="nav-items">
        <NavLink to="/" className="nav-item">
          <TimelineIcon className="analise-icon" />
          Analitika
        </NavLink>
        <NavLink to="/category" className="nav-item">
          <LayersIcon className="category-icon" />
          Kategoriyalar
        </NavLink>
        <NavLink onClick={() => changeTypeOfData("AD")} to="/ad" className="nav-item">
          <WindowIcon className="ad-icon" />
          E`lonlar
        </NavLink>
        <NavLink onClick={() => changeTypeOfData("USERS")} to="/users" className="nav-item">
          <AccountCircleIcon className="users-icon" />
          Foydalanuvchilar
        </NavLink>
        <NavLink onClick={() => changeTypeOfData("NEWS")} to="/news" className="nav-item">
          <NewspaperIcon className="news-icon" />
          Yangiliklar
        </NavLink>
        <NavLink onClick={() => changeTypeOfData("NOTIFICATIONS")} to="/notifications" className="nav-item">
          <NotificationsIcon className='notifications-icon' />
          Eslatmalar
        </NavLink>
        <NavLink onClick={() => changeTypeOfData("ANNOUNCES")} to="/announces" className="nav-item">
          <CampaignIcon className='announce-icon' />
          Reklamalar
        </NavLink>
      </div>

      <div className="asidebar-footer">
        <div className="user-data">
          <div className="user-icon-wrapper">
            <img src={UserIcon} alt="" />
          </div>
          <div className="user-data-content">
            <h3 className="user-data-title">Admin</h3>
            <span>{userData?.full_name ? userData.full_name?.split(' ')[0] : userData?.phone_number.slice(-9)}</span>
          </div>
        </div>

        <button onClick={() => setOpen(true)} className="logout">
          <LogoutIcon />
          <span>Chiqish</span>
        </button>
        <Warning purpose={Logout} title='Chiqishni xoxlaysizmi? ' text="Profilingizdagi barcha shaxsiy ma`lumotlaringiz o`chib ketadi." open={open} setOpen={setOpen} id={2} />
      </div>
    </div>
  );
};

export default AsideBar;
