import { useEffect, useState } from 'react';
import AsideBar from './components/AsideBar/AsideBar';
import Login from './pages/Login/Login';
import { Routes, Route } from 'react-router-dom';
import Main from './pages/Main/Main';
import './app.scss'
import Category from './pages/Category/Category';
import Ad from './pages/Ad/Ad';
import Users from './pages/Users/Users';
import News from './pages/News/News';
import Notifications from './pages/Notifications/Notifications';
import InternalCategory from './pages/InternalCategory/InternalCategory';
import CreateNews from './pages/CreateNews/CreateNews';
import InternalNews from './pages/InternalNews/InternalNews';
import CreateNotification from './pages/CreateNotifications/CreateNotifications';
import useFetch from './hook/useFetch';
import { useAppDispatch } from './configStore';
import { getRole, handleData, handleTotalPages } from './redux/actions';
import { useAppSelector } from './hook/useTypedSelector';
import Announces from './pages/Announces/Announces';
import CreateAnnounce from './pages/Notifications/CreateAnnounce';
import Loader from './components/Loader/Loader';

function App() {
  const { request } = useFetch();
  const dispatch = useAppDispatch();
  const { random, typeOfData, currentPage, perPage, filterItem, categoryFilterItem, searchValue} = useAppSelector(state => state);
  const [isLogggedin, setLoggedin] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      request((
        typeOfData === "NEWS" ? `/api/news/admin/list/filter/paginate/${perPage}${filterItem === "clear" ? "" : filterItem}?page=${currentPage}`
          : typeOfData === 'AD' ? `/product/admin/get/announcement/list/${perPage}${categoryFilterItem}${filterItem === "clear" ? "" : filterItem}?page=${currentPage}`
            : typeOfData === 'NOTIFICATIONS' ? `/product/admin/list/notisification/${perPage}${filterItem === "clear" ? "" : filterItem}?page=${currentPage}`
              : typeOfData === 'USERS' ? `/user/api/admin/get/user/list/${perPage}${filterItem === "clear" ? "" : filterItem}?page=${currentPage}`
                : `/api/news/admin/v2/list/get/recommend/${perPage}${filterItem === "clear" ? "" : filterItem}?page=${currentPage}`),

        "GET", null, {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      })
        .then(response => {
          if (response.success) {
            dispatch(handleData(response.data.results))
            dispatch(handleTotalPages(response.data.total_pages))
          }
        })
        .catch(err => console.log(err))
    }
  }, [random, typeOfData, currentPage, perPage, filterItem, categoryFilterItem, searchValue.length < 2]);

  useEffect(() => {
    if (searchValue.length > 1) {
      request((
        typeOfData === "NEWS" ? `/api/news/admin/list/filter/paginate/search/${searchValue}`
          : typeOfData === 'AD' ? `/product/admin/get/announcement/list/search/${searchValue}`
            : typeOfData === 'NOTIFICATIONS' ? `/product/admin/list/notisification/search/${searchValue}`
              : typeOfData === 'USERS' ? `/user/api/admin/get/user/list/search/${searchValue}`
                : `/api/news/admin/v2/list/get/recommend/search/${searchValue}`),
        "GET", null, {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      })
        .then(response => {
          if (response.success) {
            console.log(response.data.results)
            dispatch(handleData(response.data.results))
          }
        })
        .catch(err => dispatch(handleData([])))
    }
  }, [searchValue])

  useEffect(() => {
    request("/user/api/getuserinfo", "GET", null, {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    })
      .then(data => {
        setLoading(false)
        if (data?.success) {
          setLoggedin(true)
          dispatch(getRole(data.userdata.is_superuser ? 'superAdmin' : 'admin'))
        } else {
          localStorage.clear();
        }
      })
      .catch(err => console.log(err))
  }, [])

  return isLoading ?
    <Loader />
    : isLogggedin ?
      (
        <div className="main-container">
          <AsideBar />
          <div className="container">
            <Routes>
              <Route index element={<Main />} ></Route>
              <Route path="category">
                <Route index element={<Category />} />
                <Route path={`internal-category/:categoryId`} element={<InternalCategory />} />
              </Route>
              <Route path='ad' element={<Ad />} ></Route>
              <Route path='users' element={<Users />} ></Route>
              <Route path="news">
                <Route index element={<News />} />
                <Route path="create-news/:newsId" element={<CreateNews />} />
                <Route path="internal-news/:newsId" element={<InternalNews />}></Route>
              </Route>
              <Route path='notifications'>
                <Route index element={<Notifications />} ></Route>
                <Route path='create-notifications' element={<CreateNotification />}></Route>
              </Route>
              <Route path='announces'>
                <Route index element={<Announces />}></Route>
                <Route path='create-announce/:announceId' element={<CreateAnnounce />} ></Route>
              </Route>
            </Routes>
          </div>
        </div>
      )
      : <Login />;
}

export default App;
