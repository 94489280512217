import { createReducer } from "@reduxjs/toolkit";
import { ReducerWithInitialState } from "@reduxjs/toolkit/dist/createReducer";
import { category, newsTable, typeOfData } from "../data/types";
import { changeRandom, getCategories, getRole, handleCategoryFilter, handleCurrentPage, handleData, handlePerPage, handleSearchValue, handleTotalPages, handleTypeOfData, setFilter } from "./actions";

interface initialStateType {
    role: 'admin' | 'superAdmin',
    random: number,
    data: newsTable[],
    typeOfData: typeOfData,
    currentPage: number,
    totalPages: number,
    perPage: number,
    categories: category[],
    filterItem: string,
    categoryFilterItem: string,
    searchValue: string,
}

const initialState = {
    role: 'admin',
    random: 0,
    data: [],
    typeOfData: localStorage.getItem("typeOfData") || "AD",
    currentPage: 1,
    totalPages: 0,
    perPage: 10,
    categories: [],
    filterItem: '',
    categoryFilterItem: '',
    searchValue: '',
} as initialStateType;

export const reducer: ReducerWithInitialState<initialStateType> = createReducer(initialState, (builder) => {
    builder
        .addCase(getRole, (state, action) => {
            if (action.payload) {
                state.role = action.payload
            }
        })
        .addCase(changeRandom, (state, action) => {
            if (action.payload) {
                state.random = action.payload
            }
        })
        .addCase(handleData, (state, action) => {
            if (action.payload) {
                state.data = action.payload
            }
        })
        .addCase(handleTypeOfData, (state, action) => {
            if (action.payload) {
                state.typeOfData = action.payload
            }
        })
        .addCase(handleCurrentPage, (state, action) => {
            if (action.payload) {
                state.currentPage = action.payload
            }
        })
        .addCase(handleTotalPages, (state, action) => {
            if (action.payload) {
                state.totalPages = action.payload
            }
        })
        .addCase(handlePerPage, (state, action) => {
            if (action.payload) {
                state.perPage = action.payload
            }
        })
        .addCase(getCategories, (state, action) => {
            if (action.payload) {
                state.categories = action.payload
            }
        })
        .addCase(setFilter, (state, action) => {
            if (action.payload) {
                state.filterItem = action.payload
            }
        })
        .addCase(handleCategoryFilter, (state, action) => {
            if (action.payload) {
                state.categoryFilterItem = action.payload
            }
        })
        .addCase(handleSearchValue, (state, action) => {
            if (action.payload) {
                state.searchValue = action.payload
            }
        })
});