import { useNavigate } from 'react-router-dom';
import { category } from '../../data/types';
import ChangeIcon from '../../assets/svg/changeIcon.svg';
import DeleteIcon from '../../assets/svg/deleteIcon.svg';
import './categoryCollection.scss';
import Warning from '../Warning/Warning';
import { useState } from 'react';
import useFetch from '../../hook/useFetch';
import { useAppSelector } from '../../hook/useTypedSelector';

const CategoryCollection = ({ categories, setRender, render }: { categories: category[] | null, setRender: React.Dispatch<React.SetStateAction<boolean>>, render: boolean }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [id, setId] = useState<number | undefined>();
    const { role } = useAppSelector(state => state);
    const navigate = useNavigate();
    const { request } = useFetch();

    const deleteCategory = () => {
        request(`/product/admin/category/${id}/`, "DELETE", null, {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Accept-Language": 'ru'
        })
            .then(response => {
                setOpen(false);
                setRender(!render)
            })
            .catch(err => console.log(err))
    }

    return (
        <div className="categories-collection">
            {
                categories?.map((item, index) => (
                    <div key={item.pk} className="category-item">
                        <div>
                            <img className="category-image" src={item.icon} alt='catogory' />
                            <h4 className="category-name">{item.name}</h4>
                        </div>

                        <div>

                            <img src={ChangeIcon} alt="change_icon" onClick={() => navigate(`internal-category/${item.pk}`)} />
                            {
                                role === 'superAdmin' ?
                                    <img src={DeleteIcon} alt="delete_icon" onClick={() => { setId(item.pk); setOpen(true) }} />
                                    : null
                            }
                        </div>


                    </div>
                ))
            }
            <Warning id={1} purpose={deleteCategory} title="Kategoriyani oʻchirish" text="Kategoriyani o'chirishda barcha ma'lumotlar va ichki toifalar o'chiriladi, ularni qayta tiklab bo'lmaydi" open={open} setOpen={setOpen} />
        </div>
    )
}

export default CategoryCollection