import OverviewItem from './OverviewItem';
import {ReactComponent as UsersIcon} from '../../../assets/svg/users.svg';
import {ReactComponent as AdIcon} from '../../../assets/svg/ad.svg';
import {ReactComponent as ProfitIcon} from '../../../assets/svg/profit.svg';
import {ReactComponent as DownloadedIcon} from '../../../assets/svg/downloaded.svg';

const Overview: React.FC = () => {
  return (
    <div className='overview'>
      <h2 className="overview-title">Analitik tahlil</h2>
      <div className="overview-items-wrapper">
        <OverviewItem Icon = {UsersIcon} count = {"84.4K"} title={'Jami foydalanuvchilar soni'}/>
        <OverviewItem Icon={AdIcon} count = {"84.4K"} title={'E`lonlar soni'}/>
        <OverviewItem Icon={ProfitIcon} count = {"84.4K"} title={'Umumiy foyda'}/>
        <OverviewItem Icon={DownloadedIcon} count = {"84.4K"} title={'Yuklab olinishlar soni'}/>
      </div>
    </div>
  )
}

export default Overview