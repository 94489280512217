import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useRef, useState } from 'react';
import useFetch from '../../hook/useFetch';

import './createNotifications.scss'
import { useDispatch } from 'react-redux';
import { changeRandom } from '../../redux/actions';


const CreateNotifications = () => {
    const [typeNotification, setTypeNotification] = useState<string>('immediately');
    const [theme, setTheme] = useState<string>('')
    const bodyRef = useRef<HTMLTextAreaElement | null>(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [value, setValue] = useState<Dayjs | null>(
        dayjs(),
    );
    const { request } = useFetch();
    const handleChange = (newValue: Dayjs | null) => {
        setValue(newValue);
    };

    const createNotification = () => {
        console.log("first")
        if (theme && bodyRef.current?.value) {
            request("/product/admin/create/notisification", "POST", JSON.stringify({
                theme: theme,
                value: bodyRef.current?.value,
                date: value?.format('YYYY-MM-DD'),
                time: value?.format('HH:mm:ss')
            }), {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                'Content-Type': 'application/json',
            }).then(response => {
                if (response.success) {
                    alert("Added news successfully")
                    dispatch(changeRandom(Math.floor(Math.random() * 100)))
                    navigate('/notifications')
                }
            })
                .catch(err => console.log(err))
        } else {
            alert("Please fill the inputs")
        }
    };


    return (
        <div className="create-notifications">
            <div>
                <h3 className="title" onClick={() => navigate('/notifications')}><ArrowBackIcon /> Eslatmalar</h3>
                <div className="create-notifications-wrapper">
                    <div className="notifications-text-title">
                        <h3 className="notifications-title">Bildirishnoma sarlavhasi</h3>
                        <input value={theme} onChange={(e) => setTheme(e.target.value)} className="notifications-title-input" placeholder="sarlavhani kiriting" />
                        <textarea ref={bodyRef} placeholder="Matnni kiriting" name="" id="" className="notifications-text"></textarea>
                    </div>
                    <div className="set-expire-date">
                        <h3 className="expire-date-title">Qo`yilish vaqti</h3>
                        <div className="select-type-notification">
                            <label htmlFor="immediately"><input defaultChecked onChange={() => setTypeNotification('immediately')} type="radio" id="immediately" name='type' />Darhol qo`yilsin</label>
                            <label htmlFor="dately"><input onChange={() => setTypeNotification('dately')} type="radio" id="dately" name='type' />Qo`yilish vaqtini kiritish</label>
                        </div>
                        {
                            typeNotification === 'dately' &&
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'uz'}>
                                <DesktopDatePicker
                                    className="date-wrapper"
                                    label="Sana"
                                    inputFormat="MM/DD/YYYY"
                                    value={value}
                                    onChange={handleChange}
                                    renderInput={(params: any) => <TextField {...params} />}
                                />
                                <TimePicker
                                    className="time-wrapper"
                                    label="Vaqt"
                                    value={value}
                                    onChange={handleChange}
                                    renderInput={(params: any) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        }
                    </div>
                </div>
            </div>
            <div className="create-footer">
                <button className='create-footer-cancel-btn'>Отменить</button>
                <button className='create-footer-save-btn' onClick={createNotification}>Публиковать</button>
            </div>
        </div >
    )
}

export default CreateNotifications