import Chart from './Chart/Chart';
import './main.scss';
import Overview from './Overview/Overview';

const Main = () => {
  return (
    <div className='main'>
      <Overview />
      <Chart />
    </div>
  )
}

export default Main