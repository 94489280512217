import { FormControl, InputLabel, MenuItem, Pagination, Select, Stack } from '@mui/material'
import React from 'react'
import { menuItemStyle, selectStyle } from '../../../data/styles'

interface paginationComponentInterface { currentPage: number, totalPages: number, perPage: number, handleChangePerPage: (e: any) => void, setCurrentPage: (newValue: number) => void }

const PaginationComponent = ({ currentPage, totalPages, perPage, handleChangePerPage, setCurrentPage }: paginationComponentInterface) => {
    return (
        <div className="paginations-container">
            <Stack className="form-control-stack" spacing={2}>
                <Pagination
                    page={currentPage}
                    onChange={(event, page) => setCurrentPage(page)}
                    count={totalPages} variant="outlined"
                />
            </Stack>

            <FormControl className="form-control-pagination" sx={{ minWidth: 200 }} size="small">
                <InputLabel id="demo-select-small">Qatorlar soni</InputLabel>
                <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    label="Qatorlar soni"
                    sx={selectStyle}
                    value={perPage}
                    onChange={handleChangePerPage}
                >
                    <MenuItem sx={menuItemStyle} value={10}>10</MenuItem>
                    <MenuItem sx={menuItemStyle} value={25}>25</MenuItem>
                    <MenuItem sx={menuItemStyle} value={50}>50</MenuItem>
                </Select>
            </FormControl>
        </div>
    )
}

export default PaginationComponent