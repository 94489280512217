import Header from '../../components/Header.tsx/Header';
import Table from '../../components/Table/Table';
import './announces.scss';

const Announces = () => {
    return (
        <div className='announces'>
            <Header title="Reklamalar" addItemBtn={{ isExecuted: true, text: "Добавить новый" }} />
            <Table id={4} />
        </div>
    )
}

export default Announces;