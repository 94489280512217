import { useAppSelector } from '../../../hook/useTypedSelector';


const UsersTable = () => {
    const { data } = useAppSelector(state => state);

    return (
        <>
            <tr>
                <th className="row-header">Nomer</th>
                <th className="row-header">Foydalanuvchi</th>
                <th className="row-header">Tarif</th>
                <th className="row-header">Tug`ilgan sanasi</th>
                <th className="row-header">Telefon raqami</th>
            </tr>
            {
                data.map(item => (
                    <tr key={item.pk}>
                        <td className="row-item item-id">{item.pk}</td>
                        <td className="row-item">{item.full_name}</td>
                        <td className="row-item item-btn"><button className="row-item-btn">Tekin</button></td>
                        <td className="row-item">{item.birth_date}</td>
                        <td className="row-item">{item.phone_number}</td>
                    </tr>
                ))
            }
        </>
    )
}

export default UsersTable