import { useNavigate, useParams } from "react-router-dom";
import UploadIcon from '../../assets/svg/uploadIcon2.svg';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import './createNews.scss'
import { useRef, useState, useEffect } from "react";
import useFetch from "../../hook/useFetch";
import { useAppDispatch } from "../../configStore";
import { changeRandom } from "../../redux/actions";

const CreateNews = () => {
    const [theme, setTheme] = useState<string>('');
    const [img, setImg] = useState<any>();
    const text = useRef<HTMLTextAreaElement | null>(null);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { request } = useFetch();
    const { newsId } = useParams();
    const [value, setValue] = useState<Dayjs | null>(
        dayjs('2014-08-18T21:11:54'),
    );

    const handleChange = (newValue: Dayjs | null) => {
        console.log(value)
        setValue(newValue);
    };

    const addNews = () => {
        if (newsId === 'create') {
            if (theme && img && value && text.current?.value) {
                let formData = new FormData();
                formData.append("theme", theme)
                formData.append("images", img, img.name)
                formData.append("body", text.current?.value)
                formData.append("date", value?.format('YYYY-MM-DD'))
                formData.append("time", value?.format('HH:mm:ss'))

                request("/api/news/add/news/admin/", "POST", formData, {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Accept-Language": 'ru'
                })
                    .then(response => {
                        if (response.success) {
                            alert("Added news successfully")
                            dispatch(changeRandom(Math.floor(Math.random() * 100)))
                            navigate('/news')
                        }
                    })
                    .catch(err => console.log(err))
            } else {
                alert("Enter all input value")
            }
        } else {
            if (text.current?.value && value) {
                let formData = new FormData();
                formData.append("theme", theme)
                if (img) formData.append("images", img, img.name)
                formData.append("body", text.current?.value)
                formData.append("date", value?.format('YYYY-MM-DD'))
                formData.append("time", value?.format('hh:mm:ss'))

                request(`/api/news/update/destroy/api/${newsId}`, "PUT", formData, {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Accept-Language": 'ru'
                })
                    .then(response => {
                        if (response.success) {
                            alert("Changed news successfully")
                            dispatch(changeRandom(Math.floor(Math.random() * 100)))
                            navigate('/news')
                        }
                    })
                    .catch(err => console.log(err))
            } else {
                alert("Enter all input value")
            }
        }
    }

    useEffect(() => {
        if (newsId !== 'create') {
            request(`/api/news/update/destroy/api/${newsId}`, "GET", null, {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            })
                .then(response => {
                    if (response.success && text.current) {
                        setTheme(response.data.theme);
                        setValue(dayjs(`${response.data.date}T${response.data.time}`))
                        text.current.value = response.data.body;
                    }
                })
                .catch(err => console.log(err))
        }
    }, [])

    return (
        <div className="create-news">
            <div>
                <h3 className="title" onClick={() => navigate('/news')}><ArrowBackIcon />
                    Yangiliklar</h3>
                <div className="create-news-wrapper">
                    <div className="news-text-title">
                        <h3 className="news-title">Yangilik sarlavhasi</h3>
                        <input className="news-title-input" placeholder="sarlavhani kiriting" value={theme} onChange={(e) => setTheme(e.target.value)} />
                        <textarea ref={text} placeholder="Matnni kiriting" name="" id="" className="news-text"></textarea>
                    </div>
                    <div className="news-image-expire-date">
                        <div className="news-img">
                            <h3 className="news-img-title">Rasm</h3>
                            {
                                img ?
                                    <div className="uploaded_img_wrapper">
                                        <img className='uploaded-image' src={URL.createObjectURL(img)} alt="uploaded_image" />
                                        <button className='remove-selected-img-btn' onClick={() => setImg(undefined)}>Remove this image</button>
                                    </div> :
                                    <label htmlFor='news-image'>
                                        <img src={UploadIcon} alt="upload_icon" />
                                        <p>Rasm qo`shish</p>
                                    </label>
                            }
                            <input onChange={e => {
                                if (e.target.files) {
                                    setImg(e.target.files[0])
                                }
                            }
                            } type="file" id='news-image' name="myImage" accept="image/jpg, image/png, image/svg" />
                        </div>
                        <div className="set-expire-date">
                            <h3 className="expire-date-title">Nashr qilinish sanasi</h3>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'uz'}>
                                <DesktopDatePicker
                                    className="date-wrapper"
                                    label="Sana"
                                    inputFormat="YYYY/MM/DD"
                                    value={value}
                                    onChange={handleChange}
                                    renderInput={(params: any) => <TextField {...params} />}
                                />
                                <TimePicker
                                    className="time-wrapper"
                                    label="Vaqt"
                                    value={value}
                                    onChange={handleChange}
                                    renderInput={(params: any) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                </div>
            </div>
            <div className="create-footer">
                <button className='create-footer-cancel-btn' onClick={() => navigate('/news')}>Bekor qilish</button>
                <button className='create-footer-save-btn' onClick={addNews}>Nashr etish</button>
            </div>
        </div>
    )
}

export default CreateNews;