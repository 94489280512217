import { useNavigate } from 'react-router-dom';
import { addItemBtn } from '../../data/types';
import { nameOfButton } from "../../data/types";
import { useAppSelector } from '../../hook/useTypedSelector';
import './header.scss'

const Header = ({ title, addItemBtn = { isExecuted: false } }: { title: string, addItemBtn?: addItemBtn }) => {
const navigate = useNavigate();
    const { typeOfData } = useAppSelector(state => state);

    const handleClick = () => {
        if (typeOfData === 'NEWS') {
            navigate(`create-news/create`)
        }
        if (typeOfData === 'NOTIFICATIONS') {
            navigate('create-notifications')
        }
        if (typeOfData === 'ANNOUNCES') {
            navigate('create-announce/create')
        }
    }

    return (
        <div className='header'>
            <h2 className="title">{title}</h2>
            {
                addItemBtn.isExecuted ? <button onClick={handleClick} className='add-item-btn'>{addItemBtn.text}</button> : null
            }
        </div>
    )
}

export default Header