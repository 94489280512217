import React from 'react'

const OverviewItem = ({Icon, count, title} : {Icon: React.FC<React.SVGProps<SVGSVGElement>>, count: string, title: string}) => {
  return (
    <div className='overview-item'>
        <div className="icon">
            <Icon />
        </div>
        <div className="content">
            <h3 className="count">
                {count}
            </h3>
            <span className="title">
                {title}
            </span>
        </div>
    </div>

  )
}

export default OverviewItem