import dayjs, { Dayjs } from 'dayjs';
import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch } from '../../configStore';
import useFetch from '../../hook/useFetch';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import UploadIcon from '../../assets/svg/uploadIcon2.svg';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import { changeRandom } from '../../redux/actions';

const CreateAnnounce = () => {
    const { announceId } = useParams();
    const [theme, setTheme] = useState<string>('');
    const [body, setBody] = useState<string>('');
    const [img, setImg] = useState<any>();
    const text = useRef<HTMLTextAreaElement | null>(null);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { request } = useFetch();
    const [beginvalue, setBeginValue] = useState<Dayjs | null>(
        dayjs('2014-08-18T21:11:54'),
    );
    const [finishvalue, setFinishValue] = useState<Dayjs | null>(
        dayjs('2014-08-18T21:11:54'),
    );

    const handleChangeBegin = (newValue: Dayjs | null) => {
        setBeginValue(newValue);
    };

    const handleChangeFinish = (newValue: Dayjs | null) => {
        setFinishValue(newValue);
    };

    const addAnnounce = () => {
        if (announceId === 'create') {
            if (theme && img && beginvalue && finishvalue && text.current?.value) {
                let formData = new FormData();
                formData.append("title", theme)
                formData.append("image", img, img.name)
                formData.append("body", text.current?.value)
                formData.append("start_date", beginvalue?.format('YYYY-MM-DD'))
                formData.append("end_date", finishvalue?.format('YYYY-MM-DD'))
                // formData.append("time", value?.format('hh:mm:ss'))

                request("/api/news/admin/recommendation/add", "POST", formData, {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Accept-Language": 'ru'
                })
                    .then(response => {
                        if (response.success) {
                            alert("Added news successfully")
                            dispatch(changeRandom(Math.floor(Math.random() * 100)))
                            navigate('/announces')
                        }
                    })
                    .catch(err => console.log(err))
            } else {
                alert("Enter all input value")
            }
        } 
    }

    useEffect(() => {
        if (announceId !== 'create') {
            request(`/api/news/update/destroy/api/${announceId}`, "GET", null, {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            })
                .then(response => {
                    if (response.success && text.current) {
                        setTheme(response.data.theme);
                        setBody(response.data.body)
                        setBeginValue(dayjs(`${response.data.date}T${response.data.time}`))
                        text.current.value = response.data.body;
                    }
                })
                .catch(err => console.log(err))
        }
    }, [])

    return (
        <div className="create-news">
            <div>
                <h3 className="title" onClick={() => navigate('/announces')}><ArrowBackIcon /> Reklama qo`shish</h3>
                <div className="create-news-wrapper">
                    <div className="news-text-title">
                        <h3 className="news-title">Reklama sarlavhasi</h3>
                        <input className="news-title-input" placeholder="Sarlavhani kiriting" value={theme} onChange={(e) => setTheme(e.target.value)} />
                        <textarea ref={text} placeholder="Matnni kiriting" name="" id="" className="news-text"></textarea>
                    </div>
                    <div className="news-image-expire-date">
                        <div className="news-img">
                            <h3 className="news-img-title">Rasm</h3>
                            {
                                img ?
                                    <div className="uploaded_img_wrapper">
                                        <img className='uploaded-image' src={URL.createObjectURL(img)} alt="uploaded_image" />
                                        <button className='remove-selected-img-btn' onClick={() => setImg(undefined)}>Remove this image</button>
                                    </div> :
                                    <label htmlFor='news-image'>
                                        <img src={UploadIcon} alt="upload_icon" />
                                        <p>Rasm qo`shish</p>
                                    </label>
                            }
                            <input onChange={e => {
                                if (e.target.files) {
                                    setImg(e.target.files[0])
                                }
                            }
                            } type="file" id='news-image' name="myImage" accept="image/png, image/jpg, image/svg" />
                        </div>
                        <div className="set-expire-date">
                            <h3 className="expire-date-title">Boshlanish vaqti</h3>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'uz'}>
                                <DesktopDatePicker
                                    className="date-wrapper"
                                    label="Sana"
                                    inputFormat="YYYY/MM/DD"
                                    value={beginvalue}
                                    onChange={handleChangeBegin}
                                    renderInput={(params: any) => <TextField {...params} />}
                                />
                                <TimePicker
                                    className="time-wrapper"
                                    label="Vaqti"
                                    value={beginvalue}
                                    onChange={handleChangeBegin}
                                    renderInput={(params: any) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className="set-expire-end-date">
                            <h3 className="expire-date-title">Tugash vaqti</h3>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'uz'}>
                                <DesktopDatePicker
                                    className="date-wrapper"
                                    label="Sana"
                                    inputFormat="YYYY/MM/DD"
                                    value={finishvalue}
                                    onChange={handleChangeFinish}
                                    renderInput={(params: any) => <TextField {...params} />}
                                />
                                <TimePicker
                                    className="time-wrapper"
                                    label="Vaqt"
                                    value={finishvalue}
                                    onChange={handleChangeFinish}
                                    renderInput={(params: any) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                </div>
            </div>
            <div className="create-footer">
                <button className='create-footer-cancel-btn' onClick={() => navigate('/news')}>Bekor qilish</button>
                <button className='create-footer-save-btn' onClick={addAnnounce}>Qo`shish</button>
            </div>
        </div>
    )
}

export default CreateAnnounce